
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { BancoService } from '@/core/services/financeiro';
import { Banco } from '@/core/models/financeiro';

@Component 
export default class ListaBanco extends mixins(Vue,ListPage) {
  public service =  new BancoService();
  public item = new Banco();
 
  loading: boolean = false;
  dialogCadastro: boolean = false;

  titulo: string = 'Banco';
  subTitulo: string = 'tipos de bancos cadastrados no Sistema';

  options: any = {
    itemsPerPage: 15
  };  

  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Banco', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Código do banco', value: 'codigo' },
    { text: 'Nome', value: 'nome' }
  ];

  @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
 
    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search, columns, undefined, '', 'id, nome, codigo', '').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      }, 
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => (this.loading = false));
  }
 
 Novo(){
    this.item = new Banco;
    this.dialogCadastro = true; 
  } 

  mounted(){
   // this.Atualizar();
  }
}
